<template>
  <div class="CertificateManage">
    <!-- 面包屑 -->
    <navi-gation />
    <div class="formBox" v-if="flag == 1">
      <el-row :gutter="4" class="mt15" style="display: flex">
        <el-col :span="3">
          <el-input
            v-model.trim="name"
            size="small"
            :placeholder="$t('AppManage.CertificateManagePage.placeholder')"
            @keyup.enter.native="onSearch"
          ></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" size="small" v-debounce:500="onSearch">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>
      <el-row
        :gutter="4"
        class="mt15"
        style="display: flex; margin-left: -0.5px"
      >
        <el-button-group class="butGruop">
          <el-button size="small" type="primary" @click="flag = 2">{{
            $t("AppManage.CertificateManagePage.AddAndroidCertificate")
          }}</el-button>
          <el-button
            size="small"
            type="primary"
            @click="flag = 3"
            style="
              border-left: 1px solid rgb(228, 228, 230) !important;
              border-right: 1px solid rgb(228, 228, 230) !important;
            "
            >{{
              $t("AppManage.CertificateManagePage.AddiOSCertificate")
            }}</el-button
          >
          <el-button
            style="border-radius: 0px 3px 3px 0px;"
            @click="delMuch(delarr)"
            :type="typeInfo"
            :disabled="!hasSeleted"
            size="small"
            >{{ $t("public.Delete") }}</el-button
          >
        </el-button-group>
      </el-row>
      <el-row
        :gutter="16"
        class="mt15"
        style="margin-left: 0px; margin-right: 2px"
      >
        <el-table
          size="small"
          :row-key="getRowKeys"
          stripe
          fit
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          class="tableClass"
          style="width: 100%"
          :default-sort="{ prop: 'c.update_time', order: 'descending' }"
          @selection-change="handleSelectionChange"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          @sort-change="sort"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            prop="c.cer_name"
            :label="$t('AppManage.CertificateManagePage.certificateName')"
            width="180"
            sortable="custom"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.cerName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="c.os_type"
            :label="$t('public.OperatingSystem')"
            width="100"
            sortable="custom"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.osType == '10'">
                <span>iOS </span>
              </div>
              <div v-else>
                <span> Android </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="cerMemo"
            :label="$t('AppManage.CertificateManagePage.briefIntroduction')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="g.GROUPFULLNAME"
            :label="$t('public.Section')"
            width="100"
            sortable="custom"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span> {{ row.groupFullName }} </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="c.update_time"
            :label="$t('public.ModificationTime')"
            width="180"
            sortable="custom"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.updateTime | dateformat }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <span class="action_icon">
                <img
                  src="@/images/icon_download.png"
                  :title="$t('public.Download')"
                  alt=""
                  @click="downLoad(scope.row)"
                />
              </span>
              <span class="action_icon">
                <img
                  @click="edit(scope.row)"
                  src="@/images/icon_write.png"
                  :title="$t('public.Editor')"
                  alt=""
                />
              </span>
              <span class="action_icon">
                <img
                  @click="details(scope.row.id)"
                  src="@/images/icon_del.png"
                  :title="$t('public.Delete')"
                  alt=""
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页-->
        <pagination
          style="margin-right: 10px"
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </el-row>
    </div>
    <addAndroid
      ref="AndroidRef"
      v-if="flag == 2"
      :ruleFormObj="AndroidFrom"
      @addSaveAndroid="addAndroidSave"
    ></addAndroid>
    <addIOS
      ref="IosRef"
      v-if="flag == 3"
      :modelFormObj="iosFrom"
      @addSaveIOS="addIosSave"
    ></addIOS>
    <editAndroid
      ref="AndroidRef"
      v-if="flag == 4"
      :ruleFormObj="AndroidFrom"
      @eventSaveAndroid="editAndroidSave"
    ></editAndroid>
    <editIOS
      ref="IosRef"
      v-if="flag == 5"
      :modelFormObj="iosFrom"
      @eventSaveIOS="editIosSave"
    ></editIOS>
    <!-- 保存成功的弹框 -->

    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import addIOS from "./components/addiOS.vue";
import editIOS from "./components/editiOS.vue";
import naviGation from "@/components/hearder/index";
import addAndroid from "./components/addAndroid.vue";
import editAndroid from "./components/editAndroid.vue";
import dialogInfo from "@/components/promptMessage/index";
import pagination from "@/components/pagination/page.vue";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    addIOS,
    editIOS,
    pagination,
    naviGation,
    dialogInfo,
    addAndroid,
    editAndroid,
  },

  data() {
    // 这里存放数据
    return {
      typeInfo: "info", // 删除按钮默认灰色
      flag: 1, //页面切换标志
      name: "", //输入证书名称
      page: {
        total: 0, //返回数据总条数
        pageSize: 10, //每页显示条数
        orderRule: "DESC", //排序规则ASC、DESC
        currentPage: 1, //当前页码
        orderColume: "c.update_time", //排序字段
      },
      editId: [],
      delarr: [], //存放要删除的数据
      checked: false, // 全选框双向绑定

      iosFrom: {}, //父组件传给IOS表单数据
      titleInfo: "", // 提示框的提示语
      tableData: [], //表格绑定数据
      isDisabled: false,
      AndroidFrom: {}, //父组件传给安卓表单数据
      iosChildFrom: {}, //ios子组件传给父组件表单数据
      visibaelFlag: false, //提示消息默认--关闭
      AndroidChildFrom: {}, //Android子组件传给父组件表单数据
      groupFullNameShowByIndex: "",
    };
  },

  // 监听属性 类似于data概念
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },
  // 方法集合
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    //排序
    sort(column) {
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      //调用列表方法
      this.getList();
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      //调用列表方法
      this.getList();
    },
    handleSelectionChange(obj) {
      this.multipleSelection = obj;
      this.delarr = obj;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
      obj.forEach((item) => {
        this.delarr = obj;
      });
    },
    //获取列表
    async getList() {
      var params = {
        cerName: this.name, //证书名称
        pageSize: this.page.pageSize, //"每页多少数据"
        orderRule: this.page.orderRule, //"排序规则ASC、DESC"
        orderColume: this.page.orderColume, //"排序字段"
        currentPage: this.page.currentPage, //"当前页"
      };

      const res = await this.$axios.post(
        "/httpServe/cer/getDataInfo",
        params,
        true
      );

      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },
    //查询
    async onSearch() {
      var params = {
        cerName: this.name, //证书名称
        pageSize: this.page.pageSize, //"每页多少数据"
        orderRule: this.page.orderRule, //"排序规则ASC、DESC"
        orderColume: this.page.orderColume, //"排序字段"
        currentPage: this.page.currentPage, //"当前页"
      };
      const res = await this.$axios.post(
        "/httpServe/cer/getDataInfo",
        params,
        true
      );
      this.tableData = res.data.content;
      this.page.total = res.data.total;
      this.page.currentPage = 1;
    },
    //下载
    async downLoad(row) {
      var pathUrl = "";
      if (row.osType == 10) {
        row.files[0].split(",").forEach((item) => {
          if (item.substr(item.lastIndexOf(".") + 1) == "p12") {
            pathUrl = item;
          }
        });
      } else if (row.osType == 20) {
        pathUrl = row.files[0];
      }
      var param = {
        type: "protected",
        path: pathUrl,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      await this.$axios.downloadFile(res.data);
    },
    //编辑
    edit(row) {
      // console.log(row, '回显数据')
      if (row.osType == "20") {
        this.flag = 4;
        this.AndroidFrom = { ...row };
      } else if (row.osType == "10") {
        this.flag = 5;
        this.iosFrom = {
          cerAliasName: row.cerAliasName,
          cerAliasPass: row.cerAliasPass,
          cerMemo: row.cerMemo,
          cerName: row.cerName,
          cerPass: row.cerPass,
          createTime: row.createTime,
          creator: row.creator,
          files: row.files,
          groupId: row.groupId,
          id: row.id,
          osType: row.osType,
          relationNumber: row.relationNumber,
          updateTime: row.updateTime,
          updateUser: row.updateUser,
        };
      }
    },
    //IOS保存按钮
    IosSave(data) {
      this.iosChildFrom = { ...data };
      let flag = this.$refs["IosRef"].checkdateForm();
      if (flag) {
        this.IosSaveWay();
      } else {
        console.log("error submit!!");
        return false;
      }
    },
    //IOS新增数据
    addIosSave(data) {
      this.iosChildFrom = { ...data };
      let flag = this.$refs["IosRef"].checkdateForm();
      if (flag) {
        this.addIOS(); //新增方法
      } else {
        console.log("error submit!!");
        return false;
      }
    },
    //IOS编辑数据
    editIosSave(data) {
      this.iosChildFrom = { ...data };
      let flag = this.$refs["IosRef"].checkdateForm();
      if (flag) {
        this.editIOS();
      } else {
        console.log("error submit!!");
        return false;
      }
    },
    //新增IOS方法
    async addIOS() {
      //没有说明是新增保存
      var userName = $.trim(this.iosChildFrom.cerName);
      var secretKey = $.getSecretKey(userName);
      var param = {
        ids: [], //注：新增无id,修改时填id
        cerName: this.iosChildFrom.cerName,
        osType: "10",
        cerPass: this.$utils.common.encrypt(
          this.iosChildFrom.cerPass,
          "SM4",
          secretKey //基于用户名的密钥
        ),
        cerAliasName: "",
        cerAliasPass: "",
        cerMemo: this.iosChildFrom.cerMemo,
        files: [
          {
            fileName: this.iosChildFrom.fileName,
            size: this.iosChildFrom.size,
            path: this.iosChildFrom.cerPath,
          },
          {
            fileName: this.iosChildFrom.describe,
            size: this.iosChildFrom.descSize,
            path: this.iosChildFrom.descPath,
          },
        ],
      };
      const res = await this.$axios.post("/httpServe/cer/save", param, true);
      if (res.data == 0) {
        this.$message({
          message: this.$t(
            "AppManage.CertificateManagePage.SaveFailedNameAlreadyExists"
          ),
          type: "error",
          offset: 100,
        });
      } else {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.flag = 1;
        this.name = "";
        this.getList();
      }
    },
    //编辑IOS方法
    async editIOS() {
      var userName = $.trim(this.iosChildFrom?.cerName);
      var secretKey = $.getSecretKey(userName);
      var param = {
        ids: [this.iosChildFrom.id], //注：新增无id,修改时填id
        cerName: this.iosChildFrom.cerName,
        osType: "10",
        cerPass: this.$utils.common.encrypt(
          this.iosChildFrom?.cerPass,
          "SM4",
          secretKey //基于用户名的密钥
        ),
        cerAliasName: "",
        cerAliasPass: "",
        cerMemo: this.iosChildFrom.cerMemo,
        files: [
          {
            fileName: this.iosChildFrom.fileName,
            size: this.iosChildFrom.size,
            path: this.iosChildFrom.cerPath,
          },
          {
            fileName: this.iosChildFrom.describe,
            size: this.iosChildFrom.descSize,
            path: this.iosChildFrom.descPath,
          },
        ],
      };
      const res = await this.$axios.post("/httpServe/cer/save", param, true);
      if (res.data == 0) {
        this.$message({
          message: this.$t(
            "AppManage.CertificateManagePage.SaveFailedNameAlreadyExists"
          ),
          type: "error",
          offset: 100,
        });
      } else if (res.data >= 1) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.flag = 1;
        this.name = "";
        this.getList();
      }
    },
    //Android保存按钮
    addAndroidSave(data) {
      this.AndroidChildFrom = { ...data };
      let flag = this.$refs["AndroidRef"].validateForm();
      if (flag) {
        this.addAndroid(); //Android新增或编辑接口方法
      } else {
        console.log("error submit!!");
        return false;
      }
    },
    editAndroidSave(data) {
      this.AndroidChildFrom = { ...data };
      console.log(this.AndroidChildFrom.cerPath, "cerPath");
      let flag = this.$refs["AndroidRef"].validateForm();
      if (flag) {
        //如果有id,说明是编辑保存,
        this.editAndroid(); //Android新增或编辑接口方法
      } else {
        console.log("error submit!!");
        return false;
      }
    },
    //新增Android
    async addAndroid() {
      var userName = $.trim(this.AndroidChildFrom?.cerName);
      var secretKey = $.getSecretKey(userName);
      var userName1 = $.trim(this.AndroidChildFrom?.cerAliasName);
      var secretKey1 = $.getSecretKey(userName1);
      var param = {
        ids: [], //注：新增无id,修改时填id
        cerName: this.AndroidChildFrom.cerName,
        osType: "20",
        cerPass: this.$utils.common.encrypt(
          this.AndroidChildFrom?.cerPass,
          "SM4",
          secretKey //基于用户名的密钥
        ),
        cerAliasName: this.AndroidChildFrom?.cerAliasName,
        cerAliasPass: this.$utils.common.encrypt(
          this.AndroidChildFrom?.cerAliasPass,
          "SM4",
          secretKey1 //基于用户名的密钥
        ),
        cerMemo: this.AndroidChildFrom.cerMemo,
        files: [
          {
            fileName: this.AndroidChildFrom.fileName,
            size: this.AndroidChildFrom.size,
            path: this.AndroidChildFrom.cerPath,
          },
        ],
      };
      const res = await this.$axios.post("/httpServe/cer/save", param, true);
      if (res.data == 0) {
        this.$message({
          message: this.$t(
            "AppManage.CertificateManagePage.SaveFailedNameAlreadyExists"
          ),
          type: "error",
          offset: 100,
        });
      } else if (res.data >= 1) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });

        this.flag = 1;
        this.name = "";
        this.getList();
      }
    },
    //编辑Android
    async editAndroid() {
      var userName = $.trim(this.AndroidChildFrom.cerName);
      var secretKey = $.getSecretKey(userName);
      var userName1 = $.trim(this.AndroidChildFrom.cerAliasName);
      var secretKey1 = $.getSecretKey(userName1);
      var param = {
        ids: [this.AndroidChildFrom.id], //注：新增无id,修改时填id
        cerName: this.AndroidChildFrom.cerName,
        osType: "20",
        cerPass: this.$utils.common.encrypt(
          this.AndroidChildFrom.cerPass,
          "SM4",
          secretKey //基于用户名的密钥
        ),
        cerAliasName: this.AndroidChildFrom.cerAliasName,
        cerAliasPass: this.$utils.common.encrypt(
          this.AndroidChildFrom.cerAliasPass,
          "SM4",
          secretKey1 //基于用户名的密钥
        ),
        cerMemo: this.AndroidChildFrom.cerMemo,
        files: [],
      };

      const res = await this.$axios.post("/httpServe/cer/save", param, true);

      if (res.data == 0) {
        this.$message({
          message: this.$t(
            "AppManage.CertificateManagePage.SaveFailedNameAlreadyExists"
          ),
          type: "error",
          offset: 100,
        });
      } else if (res.data >= 1) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.flag = 1;
        this.name = "";
        this.getList();
      }
    },

    //单行删除
    details(id) {
      var params = {
        ids: [id],
      };
      this.$confirm(
        this.$t("AppManage.CertificateManagePage.sureDelSeleCer"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios
            .post("/httpServe/cer/delete", params, true)
            .then(() => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });

              this.page.currentPage = 1;
              this.$refs.multipleTable.clearSelection();
              this.getList();
            })
            .catch(() => {
              this.$message({
                message: this.$t(
                  "AppManage.CertificateManagePage.DeleteFailed"
                ),
                type: "error",
                offset: 100,
              });
            });
        })
        .catch(() => {
          return false;
        });
    },
    //多条删除
    delMuch(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });

      var params = {
        ids: ids,
      };

      this.$confirm(
        this.$t("AppManage.CertificateManagePage.sureDelSeleCer"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios
            .post("/httpServe/cer/delete", params, true)
            .then(() => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.page.currentPage = 1;
              this.$refs.multipleTable.clearSelection();
              this.getList();
            })
            .catch(() => {
              this.$message({
                message: this.$t(
                  "AppManage.CertificateManagePage.DeleteFailed"
                ),
                type: "error",
                offset: 100,
              });
            });
        })
        .catch(() => {
          return false;
        });
    },

    // 复选框勾选
    checkChange(selection, row) {
      let arr1 = [];
      selection.forEach((item) => {
        arr1.push(item.id);
        //去重
        this.delarr = [...new Set(arr1)];
      });
    },

    handleClose() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false; //提示消息  关闭
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
//@import url(); 引入公共css类
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.mt15 {
  padding: 10px 10px 0px;
}
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
.formBox {
  //全选弹框
  .tableCheckBox {
    border: 1px solid #1e89e5;
    border-radius: 3px;
    width: 80px;
    background-color: #fff;
    position: absolute;
    top: 34px;
    left: 2%;
    z-index: 999;
    // background:rgba(255, 255, 255,.1)
    li {
      margin-top: -1px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #575656;
      cursor: pointer;
      font-weight: 400;
      font-family: Microsoft YaHei;
      font-size: 12px;
      list-style: none !important;
      div {
        display: inline-block;
      }
    }
    li:hover {
      color: #d38a08;
      text-decoration: underline;
    }
  }
}
//表格操作图片
.action_icon {
  display: inline-block;
  height: 100% !important;
  img {
    margin-bottom: -4px !important;
  }
}
.CertificateManage {
  ::v-deep .el-button-group .el-button--primary:last-child {
    border-left-color: rgba(255, 255, 255, 0.8) !important;
  }
}
</style>
