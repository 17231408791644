<template>
  <el-form
    :model="modelForm"
    :rules="rules"
    ref="modelForm"
    label-width="121px"
    class="demo-modelForm"
    style="margin-top: 25px; margin-left: 60px"
  >
    <el-form-item
      :label="$t('AppManage.CertificateManagePage.certificateName') + '：'"
      prop="cerName"
    >
      <el-input
        size="small"
        v-model.trim="modelForm.cerName"
        maxlength="30"
        style="width: 250px"
      ></el-input>
    </el-form-item>
    <el-form-item
      :label="$t('AppManage.CertificateManagePage.UpCertificate') + '：'"
      prop="fileName"
    >
      <el-input
        size="small"
        v-model="modelForm.fileName"
        disabled
        style="width: 250px; margin-right: 10px"
        placeholder="请上传文件(.p12)"
      ></el-input>
      <el-upload
        size="small"
        action="#"
        :auto-upload="false"
        :show-file-list="false"
        class="el-button uploadBox"
        style="padding: 0"
        :on-change="changeUpload"
        multiple
        accept=".p12"
      >
        <el-button
          size="small"
          style="
            width: 98px;
            position: relative;
            background: #e5981e;
            border: none;
          "
        >
          <span
            style="
              cursor: pointer;
              margin-top: 5px;
              position: absolute;
              top: 1px;
            "
          >
            <img src="@/images/../images/look.png" alt="" />
          </span>
          <span style="padding-left: 18px; color: white">{{
            $t("AppManage.CertificateManagePage.selectFile")
          }}</span>
        </el-button>
      </el-upload>
      <el-progress
        v-if="flagStatus"
        :percentage="percentage"
        :color="customColorMethod"
        style="width: 250px"
      ></el-progress>
    </el-form-item>
    <el-form-item
      :label="$t('AppManage.CertificateManagePage.CertificatePass') + '：'"
    >
      <el-input
        size="small"
        v-model="modelForm.cerPass"
        show-password
        style="width: 250px"
      ></el-input>
    </el-form-item>
    <el-form-item
      :label="$t('AppManage.CertificateManagePage.UpDescriptionFile') + '：'"
      prop="describe"
    >
      <el-input
        size="small"
        disabled
        v-model="modelForm.describe"
        style="width: 250px; margin-right: 10px"
        placeholder="请上传文件(.mobileprovision)"
      ></el-input>
      <el-upload
        size="small"
        action="#"
        :auto-upload="false"
        :show-file-list="false"
        class="el-button uploadBox"
        style="padding: 0"
        :on-change="changeUpload1"
        multiple
        accept=".mobileprovision"
      >
        <el-button
          size="small"
          style="
            width: 124.5px;
            position: relative;
            background: #e5981e;
            border: none;
          "
        >
          <span
            style="
              cursor: pointer;
              margin-top: 5px;
              position: absolute;
              top: 1px;
            "
          >
            <img src="@/images/../images/look.png" alt="" />
          </span>
          <span style="padding-left: 18px; color: white">{{
            $t("AppManage.CertificateManagePage.UpDescriptionFile")
          }}</span>
        </el-button>
      </el-upload>
      <el-progress
        v-if="flagStatus1"
        :percentage="percentage"
        :color="customColorMethod"
        style="width: 250px"
      ></el-progress>
    </el-form-item>

    <el-form-item
      :label="
        $t('AppManage.CertificateManagePage.CertificateIntroduction') + '：'
      "
    >
      <el-input
        type="textarea"
        v-model="modelForm.cerMemo"
        :rows="4"
        style="width: 250px"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button size="small" type="primary" @click="submitForm('modelForm')"
        >{{ $t("public.Save") }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    modelFormObj: {
      type: Object,
      // 对象或数组默认值必须从一个工厂函数获取
      default: () => {
        return {};
      },
    },
  },
  data() {
    // 这里存放数据
    return {
      flagStatus: false,
      flagStatus1: false,
      percentage: 0,
      readonly: true, //输入框只读属性
      modelForm: {
        id: "",
        size: "", //上传文件大小
        cerName: "", //证书名称
        cerPass: "", //证书密码
        cerMemo: "", //证书简介
        cerPath: "", //上传文件路径
        descPath: "", //描述文件路径
        descSize: "", //描述文件大小
        fileName: "", //证书上传
        describe: "", //证书描述文件
        cerAliasName: "", //证书别名
        cerAliasPass: "", //别名密码
      },
      rules: {
        cerName: [
          {
            required: true,
            message: this.$t("AppManage.CertificateManagePage.cerNameVoid"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (/^[\u4e00-\u9fa5\w]+$/.test(value) == false) {
                callback(
                  new Error(
                    this.$t("AppManage.CertificateManagePage.cerNameReg")
                  )
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        fileName: [
          {
            required: true,
            message: this.$t(
              "AppManage.CertificateManagePage.PleaseUploadFile"
            ),
            trigger: "blur",
          },
        ],
        // cerPass: [
        //   { required: true, message: "请输入证书密码", trigger: "blur" }
        // ],
        describe: [
          {
            required: true,
            message: this.$t(
              "AppManage.CertificateManagePage.PleaseDesUploadFile"
            ),
            trigger: "blur",
          },
        ],
      },
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    //校验表单
    checkdateForm() {
      let flag = null;
      this.$refs["modelForm"].validate((valid) => {
        if (valid) {
          flag = true;
        } else {
          flag = false;
        }
      });
      return flag;
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    //文件上传
    async changeUpload(file) {
      this.percentage = 0;
      this.flagStatus = true;
      if (file.size / (1024 * 1024) > 500) {
        // 限制文件大小
        this.$message({
          message: this.$t("AppManage.CertificateManagePage.SizeLimit"),
          type: "error",
          offset: 100,
        });
        this.flagStatus = false;
        return false;
      }
      let fileName = file.name;
      let suffixArray = ["p12"]; //限制的文件类型，根据情况自己定义
      let suffix = file.name?.substring(fileName.lastIndexOf(".") + 1); //获取文件后缀名
      if (suffixArray.indexOf(suffix) === -1) {
        // debugger
        this.$message({
          message: this.$t("AppManage.CertificateManagePage.UploadpFormat"),
          type: "error",
          offset: 100,
        });
        this.flagStatus = false;
      } else {
        const form = new FormData();
        // 文件对象
        form.append("files", file.raw);
        form.append("type", "protected");
        form.append("fileType", "p12");
        form.append("moduleName", "protected");
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        if (res.data.code == 500) {
          this.$message({
            message: this.$t(
              "AppManage.CertificateManagePage.FileUploadFailed"
            ),
            type: "error",
            offset: 100,
          });
          this.flagStatus = false;
          this.flagStatus1 = false;
        }

        if (res.data.code == 200 && res.data.data[0] != null) {
          this.$message({
            message: this.$t(
              "AppManage.CertificateManagePage.FileUpSuccessfully"
            ),
            type: "success",
            offset: 100,
          });
          this.modelForm.cerPath = res.data.data[0]; //获取上传文件路径
          this.modelForm.size = file.size; //上传文件大小
          this.$set(this.modelForm, "fileName", file.name);
          this.flagStatus = false;
          this.flagStatus1 = false;
        }
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
      this.percentage = parseInt((e.loaded / e.total) * 100);
    },
    //上传文件描述
    async changeUpload1(file) {
      this.percentage = 0;
      this.flagStatus1 = true;
      console.log(file, "file上传文件描述");
      if (file.size / (1024 * 1024) > 500) {
        // 限制文件大小
        this.$message.warning(
          this.$t("AppManage.CertificateManagePage.SizeLimit")
        );
        this.flagStatus1 = false;
        return false;
      }
      let fileName = file.name;
      let suffixArray = ["mobileprovision"]; //限制的文件类型，根据情况自己定义
      let suffix = file.name?.substring(fileName.lastIndexOf(".") + 1); //获取文件后缀名
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: this.$t(
            "AppManage.CertificateManagePage.PleaseUpMobileprovisionFormat"
          ),
          type: "error",
          duration: 2000,
        });
        this.flagStatus1 = false;
      } else {
        const form = new FormData();
        // 文件对象
        form.append("files", file.raw);
        form.append("type", "protected");
        form.append("fileType", "mobileprovision");
        form.append("moduleName", "protected");
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        if (res.data.code == 500) {
          this.$message({
            message: this.$t(
              "AppManage.CertificateManagePage.FileUploadFailed"
            ),
            type: "error",
            offset: 100,
          });
          this.flagStatus = false;
          this.flagStatus1 = false;
        }
        if (res.data.code == 200) {
          this.$message({
            message: this.$t(
              "AppManage.CertificateManagePage.FileUpSuccessfully"
            ),
            type: "success",
            offset: 100,
          });
          this.modelForm.descPath = res.data.data[0]; //获取上传文件路径
          this.modelForm.descSize = file.size; //上传文件大小
          // this.$set( target, key, value )//对象是个空对象，vue识别不了字段，需要强制更新视图
          this.$set(this.modelForm, "describe", file.name);
          this.flagStatus = false;
          this.flagStatus1 = false;
        }
      }
    },
    //保存按钮
    submitForm() {
      this.$emit("addSaveIOS", this.modelForm);
    },
  },
};
</script>

<style lang="scss" scoped>
//@import url(); 引入公共css类
.uploadBox {
  border: 0px !important;
}
</style>
