<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      style="margin-top: 25px; margin-left: 60px"
    >
      <el-form-item
        :label="$t('AppManage.CertificateManagePage.certificateName') + '：'"
        prop="cerName"
      >
        <el-input
          size="small"
          v-model.trim="ruleForm.cerName"
          maxlength="30"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.CertificateManagePage.UpCertificate') + '：'"
        prop="fileName"
      >
        <el-input
          size="small"
          disabled
          v-model="ruleForm.fileName"
          :placeholder="$t('AppManage.CertificateManagePage.UpPlaceholder')"
          style="width: 250px; margin-right: 10px"
        ></el-input>
        <el-upload
          size="small"
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          class="el-button uploadBox"
          style="padding: 0"
          :on-change="changeUpload"
          multiple
          accept=".keystore,.jks"
        >
          <el-button
            size="small"
            style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            "
          >
            <span
              style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              "
            >
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{
              $t("AppManage.CertificateManagePage.selectFile")
            }}</span>
          </el-button>
        </el-upload>
        <el-progress
          v-if="flagStatus"
          :percentage="percentage"
          :color="customColorMethod"
          style="width: 250px"
        ></el-progress>
      </el-form-item>

      <el-form-item
        :label="$t('AppManage.CertificateManagePage.CertificatePass') + '：'"
      >
        <el-input
          size="small"
          v-model="ruleForm.cerPass"
          show-password
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.CertificateManagePage.CertificateAlias') + '：'"
        prop="cerAliasName"
      >
        <el-input
          size="small"
          v-model.trim="ruleForm.cerAliasName"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.CertificateManagePage.AliasPass') + '：'"
      >
        <el-input
          size="small"
          show-password
          v-model="ruleForm.cerAliasPass"
          style="width: 250px"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="
          $t('AppManage.CertificateManagePage.CertificateIntroduction') + '：'
        "
      >
        <el-input
          type="textarea"
          v-model="ruleForm.cerMemo"
          :rows="4"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')"
          >{{ $t("public.Save") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    ruleFormObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      flagStatus: false,
      percentage: 0,
      ruleForm: {
        id: "",
        size: "", //上传文件大小
        cerName: "", //证书名称
        cerPass: "", //证书密码
        cerMemo: "", //证书简介
        cerPath: "", //上传文件路径
        fileName: "", //证书上传
        cerAliasName: "", //证书别名
        cerAliasPass: "", //别名密码
      },
      rules: {
        cerName: [
          {
            required: true,
            message: this.$t("AppManage.CertificateManagePage.cerNameVoid"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (/^[\u4e00-\u9fa5\w]+$/.test(value) == false) {
                callback(
                  new Error(
                    this.$t("AppManage.CertificateManagePage.cerNameReg")
                  )
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        fileName: [
          {
            required: true,
            message: this.$t("AppManage.CertificateManagePage.fileNameVoid"),
            trigger: "blur",
          },
        ],
        cerAliasName: [
          {
            required: true,
            message: this.$t(
              "AppManage.CertificateManagePage.cerAliasNameVoid"
            ),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (/^[\u4e00-\u9fa5.\w]+$/.test(value) == false) {
                callback(
                  new Error(
                    this.$t("AppManage.CertificateManagePage.cerNameReg")
                  )
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
      },
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    //校验表单
    validateForm() {
      let flag = null;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          flag = true;
        } else {
          flag = false;
        }
      });
      return flag;
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    //文件上传
    async changeUpload(file) {
      this.percentage = 0;
      this.flagStatus = true;
      if (file.size / (1024 * 1024) > 500) {
        // 限制文件大小
        this.$message({
          message: this.$t("AppManage.CertificateManagePage.SizeLimit"),
          type: "error",
          offset: 100,
        });
        this.flagStatus = false;
        return false;
      }
      let fileName = file.name;
      let suffixArray = ["keystore", "jks"]; //限制的文件类型，根据情况自己定义
      let suffix = file.name?.substring(fileName.lastIndexOf(".") + 1); //获取文件后缀名
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: this.$t("AppManage.CertificateManagePage.FileFormatError"),
          type: "error",
          offset: 100,
        });
        this.flagStatus = false;
      } else {
        const form = new FormData();
        // 文件对象
        form.append("files", file.raw);
        form.append("type", "protected");
        form.append("fileType", "keystore");
        form.append("moduleName", "protected");
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        if (res.data.code == 500) {
          this.$message({
            message: this.$t(
              "AppManage.CertificateManagePage.FileUploadFailed"
            ),
            type: "error",
            offset: 100,
          });
          this.flagStatus = false;
        }
        if (res.data.code == 200) {
          this.ruleForm.cerPath = res.data.data[0]; //获取上传文件路径
          this.ruleForm.size = file.size; //上传文件大小
          this.$set(this.ruleForm, "fileName", file.name);
          this.$message({
            message: this.$t(
              "AppManage.CertificateManagePage.FileUpSuccessfully"
            ),
            type: "success",
            offset: 100,
          });
          this.flagStatus = false;
        }
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
      this.percentage = parseInt((e.loaded / e.total) * 100);
    },
    //保存按钮
    submitForm() {
      this.$emit("addSaveAndroid", this.ruleForm);
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadBox {
  border: 0px !important;
}
</style>
